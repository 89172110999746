(function ($) {
  $(document).on('basic-carousel-formatter.reset', '.js-basic-carousel', function () {
    Drupal.behaviors.basicCarouselFormatter.initCarousel($(this));
  });
  Drupal.behaviors.basicCarouselFormatter = {
    formatter: '.basic-carousel-formatter',
    basicSlide: '.js-basic-carousel__slide',
    autoplaySpeed: Drupal.settings.common.carousel_autoplay_time
      ? parseInt(Drupal.settings.common.carousel_autoplay_time)
      : 3000,
    isMobile: false,
    attach: function (context) {
      var self = this;
      var $formatter = $(self.formatter, context);
      var $carousel = $();

      self.isMobile = Unison.fetch.now().name === 'small';
      $formatter.each(function () {
        $carousel = $('.js-basic-carousel', $(this));
        self.initCarousel($carousel);
      });
      Unison.on('change', function (currentBreakPoint) {
        self.isMobile = currentBreakPoint.name === 'small';
        $formatter.each(function () {
          $carousel = $('.js-basic-carousel', $(this));
          if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('unslick');
          }
          $carousel.trigger('basic-carousel-formatter.reset');
        });
      });
    },
    initCarousel: function ($carousel) {
      var self = this;
      var $arrowsDiv = $('.carousel-controls', $carousel);
      var autoplay = $carousel.data('slidesAutoplay') || false;
      var slidesToShow = $carousel.data('slidesShow') || 1;
      var slidesToScroll = $carousel.data('slidesScroll') || 1;
      var omitMobileCarousel = $carousel.data('mobileDots') || 0;
      var desktopDots = omitMobileCarousel === 1 ? true : false;
      var carouselSlickSettings = {
        appendArrows: $arrowsDiv,
        slide: self.basicSlide,
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        autoplay: autoplay,
        autoplaySpeed: self.autoplaySpeed,
        arrows: true,
        dots: desktopDots
      };
      var carouselSlickMobileSettings = {
        appendArrows: $arrowsDiv,
        slide: self.basicSlide,
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        autoplay: autoplay,
        autoplaySpeed: self.autoplaySpeed,
        arrows: true,
        dots: true
      };

      if (self.isMobile === true) {
        if (omitMobileCarousel === 1) {
          return false;
        }
        $carousel.slick(carouselSlickMobileSettings);
      } else {
        $carousel.slick(carouselSlickSettings);
      }
    }
  };
})(jQuery);
